<template>
  <div class="auth-wrapper auth-wrapper-reset-password auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">

        <!-- logo -->
        <b-link class="brand-logo">
          <b-img
            :src="logoImg"
            alt="Payrite"
            style="width:150px"
          />
        </b-link>

        <b-card-title class="mb-1">
          Uitnodiging PayRite
        </b-card-title>
        <b-card-text class="mb-2"  v-if="tokenChecked && isValidToken">
          Welkom bij PayRite {{ inviteData.fullName }}! <br/><br/> Leuk dat je de uitnodiging wilt accepteren! U bent uitgenodigd door {{ inviteData.invitedBy }}
        </b-card-text>
        <b-card-text class="mb-2"  v-if="tokenChecked && !isValidToken">
          De link waarmee u deze pagina wilt bezoeken is ongeldig, probeer het nogmaals op neem contact met ons op
        </b-card-text>
        <b-card-text class="mb-2"  v-if="!tokenChecked">
         Een ogenblik geduld, we halen uw uitnodiging op...
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules" v-if="tokenChecked && isValidToken">
          <b-form
            class="auth-reset-password-form mt-2"
            @submit.prevent="validationForm"
          >

            <b-form-group
              label="Bedrijfsnaam"
              label-for="userEmail"
            >
              <b-form-input
                  v-if="inviteData.type === 2"
                id="companyName"
                v-model="inviteData.companyName"
                class="form-control-merge"
                name="companyName"
                disabled
              />
            </b-form-group>
            <b-form-group
                label="E-mailadres"
                label-for="userEmail"
            >
              <b-form-input
                  id="userEmail"
                  v-model="inviteData.email"
                  class="form-control-merge"
                  name="userEmail"
                  disabled
              />
            </b-form-group>

            <!-- password -->
            <b-form-group
              label="Nieuw wachtwoord"
              label-for="reset-password-new"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              label-for="reset-password-confirm"
              label="Bevestig wachtwoord"
            >
              <validation-provider
                #default="{ errors }"
                name="Bevestig wachtwoord"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="cPassword"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="reset-password-confirm"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              block
              type="submit"
              variant="primary"
            >
              Accepteer & maak account aan
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <feather-icon icon="ChevronLeftIcon" />
            terug naar login
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Reset Password v1 -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton, BImg,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import authenticationStoreModule from '@/views/pages/authentication/authenticationStoreModule'
import { onUnmounted } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    BImg,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      /* eslint-disable global-require */
      logoImg: require('@/assets/images/logo/payrite.png'),
      userEmail: '',
      cPassword: '',
      password: '',
      // validation
      required,
      email,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
      spinner: false,
      inviteData: {},
      tokenChecked: false,
      isValidToken: false,
      token: '',
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    this.token = this.$route.params.id
    store.dispatch('auth-invite/getInvite', {
      token: this.token,
    }).then(async response => {
      this.tokenChecked = true
      this.isValidToken = true
      this.inviteData = response.data
    }).catch(() => {
      this.tokenChecked = true
      this.isValidToken = false
    })
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.spinner = true
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          store.dispatch('auth-invite/acceptInvite', { password: this.password, token: this.token })
            .then(response => {
              this.spinner = false
              if (response.data.success) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Uitnodiging geaccepteerd',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'De uitnodiging is succesvol geaccepteerd! U kunt nu inloggen en gebruik maken van het platform.',
                  },
                })

                this.$router.push({ name: 'auth-login' })
              }
              if (!response.data.success) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Uitnodiging accepteren',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: response.data.message,
                  },
                })
              }
            })
            .catch(() => {
              this.spinner = false
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Uitnodiging accepteren',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: 'Er is een fout opgetreden, controleer de gegevens en probeer het nogmaals.',
                },
              })
            })
        }
      })
    },
  },
  setup() {
    const INVITE_APP_STORE_MODULE_NAME = 'auth-invite'

    // Register module
    if (!store.hasModule(INVITE_APP_STORE_MODULE_NAME)) store.registerModule(INVITE_APP_STORE_MODULE_NAME, authenticationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVITE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVITE_APP_STORE_MODULE_NAME)
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.auth-wrapper-reset-password{
  background: url("../../../assets/images/pages/reset-password.jpg");
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

[dir] .auth-wrapper.auth-v1 .auth-inner:before {
  background-image: none !important;
}
</style>
